@tailwind base;
@tailwind components;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

@tailwind utilities;

.image-style-align-center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}