.mySwiper .swiper-button-next,
.mySwiper .swiper-button-prev {
    color: #dbdbdb;
    padding: 8px;
}

.mySwiper .swiper-button-next:hover,
.mySwiper .swiper-button-prev:hover {
    color: #dbdbdb;
    /* Hover color for arrows */
}

.mySwiper .swiper-pagination-bullet {
    background-color: #eeeeee;
    /* Pagination bullet color */
    opacity: 1;
    /* Bullet visibility */
}

.mySwiper .swiper-pagination-bullet-active {
    background-color: #2E6D70;
    /* Active bullet color */
    transform: scale(1.2);
    /* Active bullet enlargement */
}

.mySwiper .swiper-pagination-bullet:hover {
    background-color: #2E6D70;
    /* Hover color for bullets */
}