@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
    overflow-x: hidden;
}

.grid {
    grid-template-columns: repeat(auto-fill, minmax(0, 1fr));
    gap: 1rem;
}

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
